import Vue from "vue";
import VueRouter from "vue-router";
import $$ from "../assets/js/global.js";
let urlArr = [];
const Margin = () => import("@/views/Education/views/Margin/Margin.vue");
$.ajax({
    type: "GET",
    url: $$.mibb + "GetEducationContent",
    data: { id: "", lang: localStorage.getItem("langIH") || 1 },
    dataType: "json",
    async: false,
    success: data => {
        console.log(data);
        $.each(data.text, (i, c) => {
            if (c.title) {
                urlArr.push({
                    component: Margin,
                    path: "/mst/Education/" + c.title.replace(/\s*/g, ""),
                    name: c.title,
                    meta: {
                        // 判断是否有三级导航栏
                        ViewsNav: false,
                        name: c.title,
                        id: c.id,
                        title: c.title + " - Education | Maybank Warrants",
                        img: c.image
                            ? c.image
                            : "https://warrants.maybank2u.com.my/mibbresources/assets/Education 1.jpg",
                        content: {}
                    }
                });
            }
        });
    },
    error: function (error) {
        console.log(error);
    }
});
// LandingPage
const Home = () => import("../views/Home/Home.vue");

// Warrant tools
const WarrantTools = () => import("@/views/WarrantTools/WarrantTools.vue");
// Views
const LiveMatrix = () =>
    import("@/views/WarrantTools/views/LiveMatrix/LiveMatrix.vue");
const WarrantSearch = () =>
    import("@/views/WarrantTools/views/WarrantSearch/WarrantSearch.vue");
const WarrantTerms = () =>
    import("@/views/WarrantTools/views/WarrantTerms/WarrantTerms.vue");
const WarrantComparison = () =>
    import("@/views/WarrantTools/views/WarrantComparison/WarrantComparison.vue");
const WarrantCalculator = () =>
    import("@/views/WarrantTools/views/WarrantCalculator/WarrantCalculator.vue");
const WarrantSettlementCalculator = () =>
    import("@/views/WarrantTools/views/WarrantSettlementCalculator/WarrantSettlementCalculator.vue");
const StructureYourWarrant = () =>
    import(
        "@/views/WarrantTools/views/StructureYourWarrant/StructureYourWarrant.vue"
    );
const Underlying = () =>
    import("@/views/WarrantTools/views/Underlying/Underlying.vue");

// Observations
const Observations = () => import("@/views/Observations/Observations.vue");
// Views
const TopTradedShares = () =>
    import("@/views/Observations/views/TopTradedShares/TopTradedShares.vue");
const WarrantMovers = () =>
    import("@/views/Observations/views/WarrantMovers/WarrantMovers.vue");
const DailyHighlights = () =>
    import("@/views/Observations/views/DailyHighlights/DailyHighlights.vue");
const WarrantWatch = () =>
    import("@/views/Observations/views/WarrantWatch/WarrantWatch.vue");
const WarrantsBulletin = () =>
    import("@/views/Observations/views/WarrantsBulletin/WarrantsBulletin.vue");

// Warrants Documentations
const Documents = () => import("@/views/Documents/Documents.vue");
// Views
const BaseProspectus = () =>
    import("@/views/Documents/views/BaseProspectus/BaseProspectus.vue");
const TermSheet = () =>
    import("@/views/Documents/views/TermSheet/TermSheet.vue");
const WarrantsAnnouncement = () =>
    import(
        "@/views/Documents/views/WarrantsAnnouncement/WarrantsAnnouncement.vue"
    );
const UnderlyingReports = () =>
    import("@/views/Documents/views/UnderlyingReports/UnderlyingReports.vue");

// Education
const Education = () => import("@/views/Education/Education.vue");
const EducationIndex = () => import("@/views/Education/views/Index/index.vue");
// Views
const StepstoBegin = () =>
    import("@/views/Education/views/StepstoBegin/StepstoBegin.vue");
// Views > How Do I Start > Views
const Index = () =>
    import("@/views/Education/views/StepstoBegin/views/index/index.vue");
const LearnAbout = () =>
    import(
        "@/views/Education/views/StepstoBegin/views/LearnAbout/LearnAbout.vue"
    );
const UtiliseWarrantTools = () =>
    import(
        "@/views/Education/views/StepstoBegin/views/UtiliseWarrantTools/UtiliseWarrantTools.vue"
    );
const Monitor = () =>
    import("@/views/Education/views/StepstoBegin/views/Monitor/Monitor.vue");
const Compute = () =>
    import("@/views/Education/views/StepstoBegin/views/Compute/Compute.vue");
const Accounts = () =>
    import("@/views/Education/views/StepstoBegin/views/Accounts/Accounts.vue");
const Online = () =>
    import("@/views/Education/views/StepstoBegin/views/Online/Online.vue");
// Views
const AboutWarrants = () =>
    import("@/views/Education/views/AboutWarrants/AboutWarrants.vue");
// Views > About Warrants > Views
const TypesOfWarrants = () =>
    import(
        "@/views/Education/views/AboutWarrants/views/TypesOfWarrants/TypesOfWarrants.vue"
    );
const MechanicsOfCall = () =>
    import(
        "@/views/Education/views/AboutWarrants/views/MechanicsOfCall&PutWarrants/MechanicsOfCall&PutWarrants.vue"
    );
const AdvantagesOfTradingWarrants = () =>
    import(
        "@/views/Education/views/AboutWarrants/views/AdvantagesOfTradingWarrants/AdvantagesOfTradingWarrants.vue"
    );
const BasicFormulasToKnow = () =>
    import(
        "@/views/Education/views/AboutWarrants/views/BasicFormulasToKnow/BasicFormulasToKnow.vue"
    );
const KeyIngredientsToDetermineValueOfAWarrant = () =>
    import(
        "@/views/Education/views/AboutWarrants/views/KeyIngredientsToDetermineValueOfAWarrant/KeyIngredientsToDetermineValueOfAWarrant.vue"
    );
// Views
const KeyIndicatorConcepts = () =>
    import(
        "@/views/Education/views/KeyIndicatorConcepts&terms/KeyIndicatorConcepts&terms.vue"
    );
// Views > Key Indicators Concepts & Terms > Views
const TimeDecay = () =>
    import(
        "@/views/Education/views/KeyIndicatorConcepts&terms/views/TimeDecay/TimeDecay.vue"
    );
const Gearing = () =>
    import(
        "@/views/Education/views/KeyIndicatorConcepts&terms/views/Gearing/Gearing.vue"
    );
const Delta = () =>
    import(
        "@/views/Education/views/KeyIndicatorConcepts&terms/views/Delta/Delta.vue"
    );
const ImpliedVolatility = () =>
    import(
        "@/views/Education/views/KeyIndicatorConcepts&terms/views/ImpliedVolatility/ImpliedVolatility.vue"
    );
const InventoryLiquidity = () =>
    import(
        "@/views/Education/views/KeyIndicatorConcepts&terms/views/InventoryLiquidity/InventoryLiquidity.vue"
    );
const Moneyness = () =>
    import(
        "@/views/Education/views/KeyIndicatorConcepts&terms/views/Moneyness/Moneyness.vue"
    );
// Views
const StepsInPickingTheRightWarrants = () =>
    import(
        "@/views/Education/views/StepsInPickingTheRightWarrants/StepsInPickingTheRightWarrants.vue"
    );
// Views > Steps in Picking the  Right Warrants > Views
const PickTheRightStock = () =>
    import(
        "@/views/Education/views/StepsInPickingTheRightWarrants/views/PickTheRightStock/PickTheRightStock.vue"
    );
const EstablishInvestmentStyle = () =>
    import(
        "@/views/Education/views/StepsInPickingTheRightWarrants/views/EstablishInvestmentStyle&Horizon/EstablishInvestmentStyle&Horizon.vue"
    );
const KnowYourRiskAppetite = () =>
    import(
        "@/views/Education/views/StepsInPickingTheRightWarrants/views/KnowYourRiskAppetite/KnowYourRiskAppetite.vue"
    );
const ShortlistAndStudyTheTerms = () =>
    import(
        "@/views/Education/views/StepsInPickingTheRightWarrants/views/ShortlistAndStudyTheTerms/ShortlistAndStudyTheTerms.vue"
    );
const CompareAndConclude = () =>
    import(
        "@/views/Education/views/StepsInPickingTheRightWarrants/views/CompareAndConclude/CompareAndConclude.vue"
    );
const SelectingAReputableIssuerAndMarketMaker = () =>
    import(
        "@/views/Education/views/StepsInPickingTheRightWarrants/views/SelectingAReputableIssuerAndMarketMaker/SelectingAReputableIssuerAndMarketMaker.vue"
    );
// Views
const FAQ = () => import("@/views/Education/views/FAQ/FAQ.vue");
// Views > FAQ > Views
const RoleOfMarketMaker = () =>
    import(
        "@/views/Education/views/FAQ/views/RoleOfMarketMaker/RoleOfMarketMaker.vue"
    );
const InvestingYourselfVsRoboadvisory = () =>
    import(
        "@/views/Education/views/FAQ/views/InvestingYourselfVsRoboadvisory/InvestingYourselfVsRoboadvisory.vue"
    );
const StructuredWarrantsVsOtherInvestment = () =>
    import(
        "@/views/Education/views/FAQ/views/StructuredWarrantsVsOtherInvestment/StructuredWarrantsVsOtherInvestment.vue"
    );
// Views
const Glossary = () => import("@/views/Education/views/Glossary/Glossary.vue");
const DecksAndSlides = () =>
    import("@/views/Education/views/DecksAndSlides/DecksAndSlides.vue");
const WarrantsGuide = () =>
    import("../views/Education/views/WarrantsGuide/WarrantsGuide.vue");
const WatchOurSeminars = () =>
    import("@/views/Education/views/WatchOurSeminars/WatchOurSeminars.vue");

// About Maybank Investment Bank
const AboutUs = () => import("@/views/AboutUs/AboutUs.vue");
// Views
const SonAboutUs = () =>
    import("@/views/AboutUs/views/SonAboutUs/SonAboutUs.vue");
const ContactUs = () => import("@/views/AboutUs/views/ContactUs/ContactUs.vue");
const Disclaimer = () =>
    import("@/views/AboutUs/views/Disclaimer/Disclaimer.vue");

Vue.use(VueRouter);

const routes = [
    // 路由重定向
    {
        path: "/",
        redirect: "/mst/Home",
        hidden: true,
        meta: {
            title: "Home | Maybank DW",
            content: {
                keywords:
                    "Thailand DW, Maybank Derivative Warrants (DW), Thailand Top Issuer, Thailand Top DW, Derivative Warrants (DW), Daily Highlights, DW42, DW42 Maybank, DW42 Thailand.",
                description:
                    "Maybank DW – The only DW website you need. Conduct analysis with Warrant Tools. Get information and market news on Maybank or any issued DW in Thailand, or simply contact the Maybank DW Team for any questions."
            }
        }
    },
    // LandingPage
    {
        path: "/mst/Home",
        name: "Home",
        component: Home,
        meta: {
            name: "nav.H",
            title: "Maybank DW | The Only DW Website You Need",
            content: {
                keywords:
                    "Thailand DW, Maybank Derivative Warrants (DW), Thailand Top Issuer, Thailand Top DW, Derivative Warrants (DW), Daily Highlights, DW42, DW42 Maybank, DW42 Thailand.",
                description:
                    "Maybank DW – The only warrants website you need. Conduct analysis with Warrant Tools. Get information and market news on Maybank or any issued warrants in Thailand, or simply contact the Maybank DW Team for any questions."
            }
        }
    },
    // Warrant tools
    {
        path: "/mst/WarrantTools",
        name: "Warrant tools",
        component: WarrantTools,
        meta: {
            name: "nav.WT"
        },
        // Views
        children: [
            {
                path: "/mst/WarrantTools/PriceMatrix",
                name: "Live Matrix",
                component: LiveMatrix,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.LM",
                    title: "Price Matrix - DW Tools | Maybank DW",
                    content: {
                        keywords: "Price Matrix, Bid Price,",
                        description:
                            "Review the indicative bid and offer prices for warrants listed by Maybank IB, based on various price points in the underlying (stock or index)."
                    }
                }
            },
            {
                path: "/mst/WarrantTools/WarrantSearch",
                name: "Warrant Search",
                component: WarrantSearch,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.WS",
                    title: "DW Search - DW Tools | Maybank DW",
                    content: {
                        keywords:
                            "DW Search, Trending DW, New DW, Sensitive DW, High Liquidity.",
                        description: "Search for any warrants of your choice"
                    }
                }
            },
            {
                path: "/mst/WarrantTools/WarrantTerms",
                name: "Warrant Terms",
                component: WarrantTerms,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.WTe",
                    title: "DW Terms - DW Tools | Maybank DW",
                    content: {
                        keywords: "DW Terms, DW Chart ",
                        description:
                            "Compare DW Terms of several warrants simultaneously and its key indicators for any warrants listed on Bursa Thailand."
                    }
                }
            },
            {
                path: "/mst/WarrantTools/WarrantComparison",
                name: "Warrant Comparison",
                component: WarrantComparison,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.WC",
                    title: "DW Comparison - DW Tools | Maybank DW",
                    content: {
                        keywords: "DW Comparison",
                        description:
                            "Compare warrants of the same underlying with their key factors displayed side by side for easy viewing."
                    }
                }
            },
            {
                path: '/mst/WarrantTools/WarrantCalculator',
                name: 'Warrant Calculator',
                component: WarrantCalculator,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: 'nav.WCa',
                    title: 'DW Calculator - DW Tools | Maybank DW',
                    content: {
                        keywords: 'DW Calculator, DW Settlement, DW Price',
                        description: 'DW Calculator helps investors to gauge the theoretical price of a warrant.'
                    }
                }
            },
            {
                path: '/mst/WarrantTools/WarrantSettlementCalculator',
                name: 'Warrant Settlement Calculator',
                component: WarrantSettlementCalculator,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: 'nav.WSC',
                    title: 'DW Settlement Calculator - DW Tools | Maybank DW',
                    content: {
                        keywords: 'DW Settlement Calculator',
                        description: 'DW Settlement Calculator helps investors to gauge the settlement price and cash settlement amount'
                    }
                }
            },
            {
                path: "/mst/WarrantTools/StructureYourWarrant",
                name: "Structure Your Warrant",
                component: StructureYourWarrant,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.SYW",
                    title: "Structure Your DW - DW Tools | Maybank DW",
                    content: {
                        keywords:
                            "DW Suggestion, DW Recommendation, Maybank Issued DW, Strcuture Your DW,",
                        description:
                            "This feedback mechanism provides an avenue to submit suggested warrants that investors are interested in."
                    }
                }
            },
            {
                path: "/mst/WarrantTools/TechnicalChart",
                name: "Technical Chart",
                component: Underlying,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.TC",
                    title: "Technical Chart - DW Tools | Maybank DW",
                    content: {
                        keywords: `Technical Chart, Technical Analysis `,
                        description: `Perform technical analysis on the underlying charts to study the direction of the warrant's underlying.`
                    }
                }
            }
        ]
    },
    // Observations
    {
        path: "/mst/Observations",
        name: "Observations",
        component: Observations,
        meta: {
            name: "nav.Ob"
        },
        // Views
        children: [
            {
                path: "/mst/Observations/TopTradedUnderlyings",
                name: "Top Traded Shares",
                component: TopTradedShares,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.TTS",
                    title: "Top Traded Shares - Market Overview | Maybank DW",
                    content: {
                        keywords: "Thailand Top Traded Shares,",
                        description:
                            "Top 10 underlying shares ranked by volume with selected corresponding Maybank IB issued warrants."
                    }
                }
            },
            {
                path: "/mst/Observations/WarrantMovers",
                name: "Warrant Movers",
                component: WarrantMovers,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.WM",
                    title: "DW Movers - Market Overview | Maybank DW",
                    content: {
                        keywords: "Top Traded DW, Top Gainer DW. ",
                        description: "Top 10 ranking of Thailand warrants by category."
                    }
                }
            },
            // {
            //     path: "/mst/Observations/WarrantsWatch",
            //     name: "Warrants Watch",
            //     component: WarrantWatch,
            //     meta: {
            //         // 判断是否有三级导航栏
            //         ViewsNav: false,
            //         name: "nav.WW",
            //         title: "DW Watch - Market Overview | Maybank DW",
            //         content: {
            //             keywords:
            //                 "DW Information, Daily Highlights, DW News, Sector DW Write Up, WarraDWnt Suggestion, DW Recommendation,",
            //             description: "Maybank Insights on selected warrants."
            //         }
            //     }
            // },
            // {
            //     path: 'https://telegram.org/',
            //     name: 'Telegram Live',
            //     meta: {
            //         // 判断是否有三级导航栏
            //         ViewsNav: false,
            //         title: 'Telegram Live - Market Overview | Maybank DW',
            //         content: {}
            //     }
            // },
            // {
            //     path: "/mst/Observations/WarrantsBulletin",
            //     name: "Warrants Bulletin",
            //     component: WarrantsBulletin,
            //     meta: {
            //         // 判断是否有三级导航栏
            //         ViewsNav: false,
            //         name: "nav.WB",
            //         title: "DW Bulletin - Market Overview | Maybank DW",
            //         content: {
            //             keywords: "Structured DW news and updates. ",
            //             description:
            //                 "DW Information, Daily Highlights, DW News, Expiring DW, DW To Invest"
            //         }
            //     }
            // }
        ]
    },
    // Warrants Documentations
    // {
    //     path: "/mst/Documents",
    //     name: "Documents",
    //     component: Documents,
    //     meta: {
    //         name: "nav.Do"
    //     },
    //     // Views
    //     children: [
    //         //   {
    //         //     path: "/mst/Documents/BaseProspectus",
    //         //     name: "Base Prospectus",
    //         //     component: BaseProspectus,
    //         //     meta: {
    //         //       // 判断是否有三级导航栏
    //         //       ViewsNav: false,
    //         //       name: "nav.BP",
    //         //       title: "Base Prospectus - Documents | Maybank Warrants",
    //         //       content: {
    //         //         keywords: "Maybank Warrants Base Prospectus",
    //         //         description:
    //         //           "It is advised that you should review these documents before investing."
    //         //       }
    //         //     }
    //         //   },
    //         //   {
    //         //     path: "/mst/Documents/TermSheet",
    //         //     name: "Term Sheet",
    //         //     component: TermSheet,
    //         //     meta: {
    //         //       // 判断是否有三级导航栏
    //         //       ViewsNav: false,
    //         //       name: "nav.TS",
    //         //       title: "Term Sheet - Documents | Maybank Warrants",
    //         //       content: {
    //         //         keywords: "Maybank Warrants Term Sheet, Maybank Documents",
    //         //         description:
    //         //           "It is advised that you should review these documents before investing."
    //         //       }
    //         //     }
    //         //   },
    //         {
    //             path: "/mst/Documents/WarrantsAnnouncement",
    //             name: "Warrants Announcement",
    //             component: WarrantsAnnouncement,
    //             meta: {
    //                 name: "nav.WA",
    //                 title: "Warrants Announcement - Documents | Maybank Warrants",
    //                 content: {
    //                     keywords: "Warrants Announcement",
    //                     description:
    //                         "Announcements relating to Maybank issued warrants including corporate action adjustments. "
    //                 }
    //             }
    //         }

    //         // {
    //         //   path: '/mst/Documents/UnderlyingReports',
    //         //   name: 'Underlying Reports',
    //         //   component: UnderlyingReports,
    //         //   meta: {
    //         //     // 判断是否有三级导航栏
    //         //     ViewsNav: false,
    //         //     title: '',
    //         //     content: {}
    //         //   }
    //         // }
    //     ]
    // },
    // Education
    {
        path: "/mst/Education",
        name: "Education",
        component: Education,
        meta: {
            name: "nav.Ed"
        },
        // Views
        children: [
            // How Do I Start
            // {
            //     path: '/mst/Education/StepstoBegin',
            //     redirect: '/mst/Education/StepstoBegin/index',
            //     name: 'Steps to Begin',
            //     hidden: true,
            //     component: StepstoBegin,
            //     meta: {
            //         // 判断是否有三级导航栏
            //         ViewsNav: true,
            //         title: '',
            //         content: {}
            //     },
            //     // Views
            //     children: [{
            //             path: '/mst/Education/StepstoBegin/index',
            //             name: 'Steps to Begin',
            //             component: Index,
            //             meta: {
            //                 title: '',
            //                 content: {}
            //             }
            //         },
            //         {
            //             path: '/mst/Education/StepstoBegin/Accounts',
            //             name: 'Set Up Trading Accounts',
            //             component: Accounts,
            //             meta: {
            //                 title: '',
            //                 content: {}
            //             }
            //         },
            //         {
            //             path: '/mst/Education/StepstoBegin/LearnAbout',
            //             name: 'Learn About Warrants',
            //             component: LearnAbout,
            //             meta: {
            //                 title: '',
            //                 content: {}
            //             }
            //         },
            //         {
            //             path: '/mst/Education/StepstoBegin/UtiliseWarrantTools',
            //             name: 'Utilise Warrant Tools',
            //             component: UtiliseWarrantTools,
            //             meta: {
            //                 title: '',
            //                 content: {}
            //             }
            //         },
            //         {
            //             path: '/mst/Education/StepstoBegin/Online',
            //             name: 'Trade Onlne',
            //             component: Online,
            //             meta: {
            //                 title: '',
            //                 content: {}
            //             }
            //         },
            //         {
            //             path: '/mst/Education/StepstoBegin/Monitor',
            //             name: 'Monitor Warrants Trade',
            //             component: Monitor,
            //             meta: {
            //                 title: '',
            //                 content: {}
            //             }
            //         },
            //         {
            //             path: '/mst/Education/StepstoBegin/Settlement&Closing',
            //             name: 'Settlement & Closing',
            //             component: Compute,
            //             meta: {
            //                 title: '',
            //                 content: {}
            //             }
            //         },
            //     ]
            // },
            // About Warrants
            // {
            //   // path: '/mst/Education/AboutWarrants',
            //   path: '',
            //   name: 'About Warrants',
            //   component: AboutWarrants,
            //   meta: {
            //     // 判断是否有三级导航栏
            //     ViewsNav: true,
            //     title: '',
            //     content: {}
            //   },
            //   // Views
            //   children: [{
            //     path: '/mst/Education/AboutWarrants/TypesOfWarrants',
            //     name: 'Types of Warrants',
            //     component: TypesOfWarrants,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/AboutWarrants/MechanicsOfCall',
            //     name: 'Mechanics of Call & Put Warrants',
            //     component: MechanicsOfCall,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/AboutWarrants/AdvantagesOfTradingWarrants',
            //     name: 'Advantages of Trading Warrants',
            //     component: AdvantagesOfTradingWarrants,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/AboutWarrants/BasicFormulasToKnow',
            //     name: 'Basic Formulas to Know',
            //     component: BasicFormulasToKnow,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/AboutWarrants/KeyIngredientsToDetermineValueOfAWarrant',
            //     name: 'Key Ingredients to Determine Value of a Warrant',
            //     component: KeyIngredientsToDetermineValueOfAWarrant,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }]
            // },
            // Key Indicators Concepts & Terms
            // {
            //   // path: '/mst/Education/KeyIndicatorConcepts',
            //   path: '',
            //   name: 'Key Indicator Concepts & terms',
            //   component: KeyIndicatorConcepts,
            //   meta: {
            //     // 判断是否有三级导航栏
            //     ViewsNav: true,
            //     title: '',
            //     content: {}
            //   },
            //   // Views
            //   children: [{
            //     path: '/mst/Education/KeyIndicatorConcepts/TimeDecay',
            //     name: 'Time Decay',
            //     component: TimeDecay,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/KeyIndicatorConcepts/Gearing',
            //     name: 'Gearing',
            //     component: Gearing,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/KeyIndicatorConcepts/Delta',
            //     name: 'Delta',
            //     component: Delta,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/KeyIndicatorConcepts/ImpliedVolatility',
            //     name: 'Implied Volatility',
            //     component: ImpliedVolatility,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/KeyIndicatorConcepts/InventoryLiquidity',
            //     name: 'Inventory / Liquidity',
            //     component: InventoryLiquidity,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/KeyIndicatorConcepts/Moneyness',
            //     name: 'Moneyness',
            //     component: Moneyness,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }]
            // },
            // Steps in Picking the Right Warrants
            // {
            //   // path: '/mst/Education/StepsInPickingTheRightWarrants',
            //   path: '',
            //   name: 'Steps in Picking the Right Warrants',
            //   component: StepsInPickingTheRightWarrants,
            //   meta: {
            //     // 判断是否有三级导航栏
            //     ViewsNav: true,
            //     title: '',
            //     content: {}
            //   },
            //   // Views
            //   children: [{
            //     path: '/mst/Education/StepsInPickingTheRightWarrants/PickTheRightStock',
            //     name: 'Pick the right stock',
            //     component: PickTheRightStock,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/StepsInPickingTheRightWarrants/EstablishInvestmentStyle',
            //     name: 'Establish investment style & horizon',
            //     component: EstablishInvestmentStyle,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/StepsInPickingTheRightWarrants/KnowYourRiskAppetite',
            //     name: 'Know Your Risk Appetite',
            //     component: KnowYourRiskAppetite,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/StepsInPickingTheRightWarrants/ShortlistAndStudyTheTerms',
            //     name: 'Shortlist and study the Terms',
            //     component: ShortlistAndStudyTheTerms,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/StepsInPickingTheRightWarrants/CompareAndConclude',
            //     name: 'Compare and conclude',
            //     component: CompareAndConclude,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/StepsInPickingTheRightWarrants/SelectingAReputableIssuerAndMarketMaker',
            //     name: 'Selecting a Reputable Issuer and Market Maker',
            //     component: SelectingAReputableIssuerAndMarketMaker,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }]
            // },
            // FAQ
            // {
            //   // path: '/mst/Education/FAQ',
            //   path: '',
            //   name: 'FAQ',
            //   component: FAQ,
            //   meta: {
            //     // 判断是否有三级导航栏
            //     ViewsNav: true,
            //     title: '',
            //     content: {}
            //   },
            //   // Views
            //   children: [{
            //     path: '/mst/Education/FAQ/RoleOfMarketMaker',
            //     name: 'Role of market maker',
            //     component: RoleOfMarketMaker,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/FAQ/InvestingYourselfVsRoboadvisory',
            //     name: 'Investing yourself vs Roboadvisory',
            //     component: InvestingYourselfVsRoboadvisory,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }, {
            //     path: '/mst/Education/FAQ/StructuredWarrantsVsOtherInvestment',
            //     name: 'Structured Warrants vs Other Investment',
            //     component: StructuredWarrantsVsOtherInvestment,
            //     meta: {
            //       title: '',
            //       content: {}
            //     }
            //   }]
            // },
            {
                path: "/mst/Education/Education",
                name: "EducationIndex",
                component: EducationIndex,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    title: "index - Education | Maybank DW",
                    content: {
                        keywords: "",
                        description: ""
                    }
                }
            },
            // Indicators
            {
                path: "/mst/WarrantsGuide",
                name: "WarrantsGuide",
                component: WarrantsGuide,
                meta: {
                    name: "nav.WG",
                    title: "DW Guide - Education| DW Guide",
                    img: require("../../public/image/EducationIndex_1.jpg"),
                    content: {
                        keywords: "",
                        description: ""
                    }
                }
            },
            {
                path: "/mst/Education/Glossary",
                name: "Glossary",
                component: Glossary,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.Gl",
                    title: "Glossary - Education | Maybank DW",
                    img: require("../../public/image/EducationIndex_2.png"),
                    content: {
                        keywords: "DW Terminologies",
                        description:
                            "Collection of investing terminologies you may come across in warrant trading"
                    }
                }
            },
            {
                path: "/mst/Education/DecksAndSlides",
                name: "Decks and Slides",
                component: DecksAndSlides,
                meta: {
                    // 判断是否有三级导航栏
                    ViewsNav: false,
                    name: "nav.DaS",
                    title: "Decks and Slides - Education | Maybank DW",
                    img: require("../../public/image/EducationIndex_3.png"),
                    content: {
                        keywords: "Maybank Webinar Decks, Maybank Webinar Presentation",
                        description:
                            "Download decks from webinars or information relating to DW Trading and the Maybank DW Website"
                    }
                }
            },

            ...urlArr
            // {
            //   path: '/mst/Education/WatchOurSeminars',
            //   name: 'Watch Our Seminars',
            //   component: WatchOurSeminars,
            //   meta: {
            //     // 判断是否有三级导航栏
            //     ViewsNav: false,
            //     title: '',
            //     content: {}
            //   }
            // }
        ]
    },
    // About Maybank Investment Bank
    {
        path: "/mst/AboutUs",
        name: "About Us",
        component: AboutUs,
        meta: {
            name: "nav.AU"
        },
        // Views
        children: [
            {
                path: "/mst/AboutUs/AboutUs",
                name: "About Us",
                component: SonAboutUs,
                meta: {
                    name: "nav.AU",
                    title: "About Us | Maybank DW",
                    content: {
                        keywords: "",
                        description: "History and background of Maybank Investment Bank"
                    }
                }
            },
            {
                path: "/mst/AboutUs/ContactUs",
                name: "Contact Us",
                component: ContactUs,
                meta: {
                    name: "nav.CU",
                    title: "Contact Us - About Us | Maybank DW",
                    content: {
                        keywords: "",
                        description: ""
                    }
                }
            },
            {
                path: "/mst/AboutUs/Disclaimer",
                name: "Disclaimer",
                component: Disclaimer,
                meta: {
                    name: "nav.Di",
                    title: "Disclaimer Us - About Us | Maybank DW",
                    content: {
                        keywords: "",
                        description:
                            "Disclaimer and terms and conditions to use the Maybank DW Website."
                    }
                }
            }
        ]
    }
];

// 解决重复触发了同一个路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
