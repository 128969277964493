import "./jquery-ui.min.js";
import router from "../../router";
import { blackScholes } from "./black-scholes";

let id, idmail;
// (id = "https://uatmibb.nifi-is.com/mibbwebservice/"),
//   (idmail = "https://uatmibb.nifi-is.com/mibbmailservice/");
//id = "https://uatmibbth.nifi-is.com/mibbwebservice/";
//idmail = "https://uatmibbth.nifi-is.com/mibbmailservice/";

id = "https://dw42.maybank.com/mibbwebservice/";
idmail = "https://dw42.maybank.com/mibbmailservice/";

export default {
    mibb: id,
    mibbmail: idmail,

    /*************** search combobox ******************/
    jqcombobox: function (selectorelement) {
        $.widget("custom.combobox", {
            _create: function () {
                this.wrapper = $("<span>")
                    .addClass("custom-combobox")
                    .insertAfter(this.element);

                this.element.hide();
                this._createAutocomplete();
                this._createShowAllButton();
            },

            _createAutocomplete: function () {
                var selected = this.element.children(":selected"),
                    value = selected.val() ? selected.text() : "";

                this.input = $("<input>")
                    .appendTo(this.wrapper)
                    .val(value)
                    .attr("title", "")
                    .addClass(
                        "custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left"
                    )
                    .autocomplete({
                        delay: 0,
                        minLength: 0,
                        source: $.proxy(this, "_source")
                    })
                    .tooltip({
                        classes: {
                            "ui-tooltip": "ui-state-highlight"
                        }
                    });

                this._on(this.input, {
                    autocompleteselect: function (event, ui) {
                        ui.item.option.selected = true;
                        this._trigger("select", event, {
                            item: ui.item.option
                        });
                    },

                    autocompletechange: "_removeIfInvalid"
                });
            },

            _createShowAllButton: function () {
                var input = this.input,
                    wasOpen = false,
                    wrapper = this.wrapper;

                $("<a>")
                    .attr("tabIndex", -1)
                    // .attr("title", "Show All Items")
                    .attr("spellcheck", "false")
                    .tooltip()
                    .appendTo(this.wrapper)
                    .button({
                        icons: {
                            primary: "ui-icon-caret-1-s"
                        },
                        text: false
                    })
                    .removeClass("ui-corner-all")
                    .addClass("custom-combobox-toggle ui-corner-right")
                    .on("mousedown", function () {
                        wasOpen = input.autocomplete("widget").is(":visible");
                    })
                    .on("click", function () {
                        input.trigger("focus");

                        // Close if already visible
                        if (wasOpen) {
                            return;
                        }
                    });
                wrapper.on("click", function () {
                    input.autocomplete({
                        open: function () {
                            $(".ui-menu").width(input.outerWidth(true));
                        }
                    });
                    input.autocomplete("search", "");
                });
            },

            _source: function (request, response) {
                var matcher = new RegExp(
                    $.ui.autocomplete.escapeRegex(request.term),
                    "i"
                );
                response(
                    this.element.children("option").map(function () {
                        var text = $(this).text();
                        if (this.value && (!request.term || matcher.test(text)))
                            return {
                                label: text,
                                value: text,
                                option: this
                            };
                    })
                );
            },

            _removeIfInvalid: function (event, ui) {
                // Selected an item, nothing to do
                if (ui.item) {
                    return;
                }

                // Search for a match (case-insensitive)
                var value = this.input.val(),
                    valueLowerCase = value.toLowerCase(),
                    valid = false;
                this.element.children("option").each(function () {
                    if (
                        $(this)
                            .text()
                            .toLowerCase() === valueLowerCase
                    ) {
                        this.selected = valid = true;
                        return false;
                    }
                });

                // Found a match, nothing to do
                if (valid) {
                    return;
                }

                // Remove invalid value
                this.input
                    .val("")
                    // .attr("title", "No results fond. Please try another keyword.")
                    .tooltip("open");
                this.element.val("");
                this._delay(function () {
                    this.input.tooltip("close").attr("title", "");
                }, 2500);
                this.input.autocomplete("instance").term = "";
            },

            _destroy: function () {
                this.wrapper.remove();
                this.element.show();
            }
        });
    },
    // 下载功能
    MatrixExport_table(divBoxArr, name) {
        let _this = this;
        var dataText = "";
        divBoxArr.forEach(function (it, index) {
            if (document.querySelectorAll(it.div).length == 0) {
                dataText += 'Selector "' + it.div + '" not exists!';
            }
            dataText += _this.MatrixGet_table_data(it.div, it.name);
            dataText += "\n";
        });
        this.MatrixExport_csv(dataText, name);
    },
    MatrixGet_table_data(selector, name) {
        var data = "";
        $.each(document.querySelectorAll(selector + " tr"), function (t, v) {
            var tmp = [];
            $.each(v.querySelectorAll("th,td"), function (e, i) {
                if (t == 0 && e == 1) {
                    tmp.push(i.innerText.replace("\n", ""));
                    tmp.push(i.innerText.replace("\n", ""));
                } else {
                    tmp.push(i.innerText.replace("\n", "")); // 单元格里含有多个html元素可能会获取到换行符
                }
            });
            data += tmp.join(",") + "\n";
        });
        data = `UNDERLYING,${data.split(",")[0]}\nWARRANT,${data.split(",")[1]
            }\n${data}`;
        return data;
    },
    MatrixExport_csv(data, name) {
        if (window.navigator.msSaveOrOpenBlob) {
            //ie
            var blob = new Blob([decodeURIComponent(encodeURI(data))], {
                type: "text/csv;charset=utf-8;"
            });
            navigator.msSaveBlob(blob, `${name}.csv`);
        } else {
            // “\ufeff” BOM头
            var uri = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(data);
            var downloadLink = document.createElement("a");
            downloadLink.href = uri;
            downloadLink.download = `${name}.csv`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    },
    // 下载之后的名字
    export_name(name) {
        let day = new Date();
        return `${name}_${day.getFullYear()}${day.getMonth() + 1}${day.getDate()}`;
    },
    /**************** Matrix打印生成表格 ***************/
    MatrixPringtingTable(printData) {
        document.body.innerHTML = printData;
        window.print(); //打印
        location.reload();
        return false;
    },
    /************table to excel**************/
    export_csv(data, name) {
        if (window.navigator.msSaveOrOpenBlob) {
            //ie
            var blob = new Blob([decodeURIComponent(encodeURI(data))], {
                type: "text/csv;charset=utf-8;"
            });
            navigator.msSaveBlob(blob, name + ".csv" || "temp.csv");
        } else {
            // “\ufeff” BOM头
            var uri = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(data);
            var downloadLink = document.createElement("a");
            downloadLink.href = uri;
            downloadLink.download = name + ".csv" || "temp.csv";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    },
    export_table(selector, name) {
        if (document.querySelectorAll(selector).length == 0) {
            alert('Selector "' + selector + '" not exists!');
            return false;
        }
        this.export_csv(this.get_table_data(selector), name);
    },
    get_table_data(selector) {
        var data = "";
        $.each(document.querySelectorAll(selector + " tr"), function (t, v) {
            var tmp = [];
            $.each(v.querySelectorAll("th,td"), function (e, i) {
                if (t == 0 && e == 1) {
                    tmp.push(i.innerText.replace("\n", ""));
                    tmp.push(i.innerText.replace("\n", ""));
                } else {
                    tmp.push(i.innerText.replace("\n", "")); // 单元格里含有多个html元素可能会获取到换行符
                }
            });
            data += tmp.join(",") + "\n";
        });
        data = `UNDERLYING,${data.split(",")[0]}\nWARRANT,${data.split(",")[1]
            }\n${data}`;
        return data;
    },
    /***yyyy-mm-dd 00:00:00 ==> dd JAN yy****/
    en_mon_arr: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ],
    en_mon_arr1: {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
    },
    formatDate(data) {
        var date =
            data.substring(8, 10) +
            " " +
            this.en_mon_arr[Number(data.substring(5, 7)) - 1] +
            " " +
            data.substring(0, 4);
        return date;
    },
    /**************** 转换为自己时区 ***************/
    changeToLocalTime(time) {
        if (time.indexOf("/") < 0) {
            if (time.split(" ")[3]) {
                time = `${time.split(" ")[2]}/${this.en_mon_arr1[time.split(" ")[1]]}/${time.split(" ")[0]
                    } ${time.split(" ")[3]}:00`;
            } else {
                time = `${time.split(" ")[2]}/${this.en_mon_arr1[time.split(" ")[1]]}/${time.split(" ")[0]
                    } 00:00:00`;
            }
        }
        var dateMilliseconds = new Date(time).getTime();
        var timezonemillsecond = -new Date().getTimezoneOffset() * 60000;
        dateMilliseconds = dateMilliseconds + timezonemillsecond;
        return new Date(dateMilliseconds);
    },
    changeToLocalTime1(time) {
        time = time.replace(/-/g, "/");
        let dateMilliseconds = new Date(time).getTime() - (1 * 60 * 60 * 1000);
        let date = new Date(dateMilliseconds)
        console.log(date.toString().split(' ')[4]);
        return date.toString().split(' ')[2] + ' ' + date.toString().split(' ')[1] + ' ' + date.toString().split(' ')[3] + ' ' + date.toString().split(' ')[4].slice(0, 5) + ' (GMT+7)';
    },
    /*** dd JAN yy ==> yyyy/mm/dd 00:00:00****/
    formatDetailDate(data) {
        var date =
            "20" +
            data.substring(7, 9) +
            "/" +
            this.en_mon_arr1[data.substring(3, 6)] +
            "/" +
            data.substring(0, 2) +
            " 00:00:00";
        return date;
    },
    // 时间转换
    transitionData: function (time) {
        var MMM = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        return (
            time.getDate() +
            " " +
            MMM[time.getMonth()] +
            " " +
            time
                .getFullYear()
                .toString()
                .substring(0, 4)
        );
    },
    //   /**
    // * 将数值四舍五入后格式化.
    // * @param num 数值(Number或者String)
    // * @param cent 要保留的小数位(Number)
    // * @param isThousand 是否需要千分位 0:不需要,1:需要(数值类型);
    // * @return 格式的字符串,如'1,234,567.45'
    // * @type String
    // */
    formatNumber: function (num, cent, isThousand) {
        var sign, cents;
        if (num == "-" || num == undefined) {
            return "-";
        }
        num = num.toString().replace(/\$|\,/g, "");
        // 检查传入数值为数值类型
        if (isNaN(num)) {
            num = "0";
        }
        // 获取符号(正/负数)
        sign = num == (num = Math.abs(num));
        num = Math.floor(num * Math.pow(10, cent) + 0.50000000001); // 把指定的小数位先转换成整数.多余的小数位四舍五入
        cents = num % Math.pow(10, cent); // 求出小数位数值
        num = Math.floor(num / Math.pow(10, cent)).toString(); // 求出整数位数值
        cents = cents.toString(); // 把小数位转换成字符串,以便求小数位长度
        // 补足小数位到指定的位数
        while (cents.length < cent) {
            cents = "0" + cents;
        }
        if (isThousand) {
            // 对整数部分进行千分位格式化.
            for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {

                num =
                    num.substring(0, num.length - (4 * i + 3)) +
                    "," +
                    num.substring(num.length - (4 * i + 3));

            }


        }
        if (cent > 0) {
            return (sign ? "" : "-") + num + "." + cents;
        } else {
            return (sign ? "" : "-") + num;
        }
    },
    /********* >1 保留2位  <1保留3位  **********/
    reservedNumber(num) {
        if (num == "-" || num == undefined) {
            return "-";
        } else {
            return this.formatNumber(num, 3, 1);
        }
    },
    /**************** 转为千位值 ***************/
    kilobitNumber(num) {
        if (parseInt(num).toString().length >= 4) {
            return this.formatNumber(num, 3, 1);
        } else {
            return num;
        }
    },
    /**************** RM 1.6  ***************/
    formarExercisePrice(data) {
        if (data.indexOf(" ") > -1) {
            return (
                data.split(" ")[0] + " " + this.formatNumber(data.split(" ")[1], 3, 1)
            );
        } else {
            return this.formatNumber(data, 3, 1);
        }
    },
    /********* 货币改动 如果是MYR也返回RM  **********/
    curreryRetrun(currery) {
        if (currery !== undefined) {
            if (currery === "MYR") {
                return "RHT";
            } else {
                return currery;
            }
        } else {
            return " ";
        }
    },
    /**** 判断issue是否是MAYBANK return true/false ****/
    isMAYBANK(value) {
        if (value === "MST") {
            return true;
        } else {
            return false;
        }
    },
    /**************** 数据为空 为"N/A" ***************/
    spaceData(data) {
        if (!data || data == "" || data == undefined || data == "-") {
            data = "N/A";
        }
        return data;
    },
    /**************** change正数添加+ ***************/
    changeRate(num) {
        if (num <= 0) {
            return num;
        }
        return "+" + num;
    },
    /**************** 截掉千位后的数值 ***************/
    clearRM(num) {
        if (num && num !== undefined) {
            num = num / 1000;
            return num;
        }
    },
    /**************** day / days ***************/
    formatdays(date) {
        if (date <= 1) {
            return date + " day";
        } else if (date > 1) {
            return date + " days";
        } else {
            return "-";
        }
    },
    // 过滤
    filtration(data, value) {
        let showList = [];
        let Nvalue = value.replace(" ", "");
        showList = data.filter(function (tiem) {
            return (
                tiem.name.toLowerCase().indexOf(Nvalue.toLowerCase()) >= 0 ||
                Nvalue.toLowerCase().indexOf(tiem.name.toLowerCase()) >= 0
            );
        });
        return showList;
    },
    // 是input为空
    inputEmpty(id) {
        let oldVal;
        $(id)
            .find("input")
            .off("click")
            .on("click", function () {
                oldVal = $(this).val();
                $(this).val("");
            })
            .off("blur")
            .on("blur", function () {
                if (!oldVal) {
                    oldVal = $(this).val();
                }
                $(this).val(oldVal);
            });
    },
    //跳转到相应页面
    toRelevantPage: function (judge, ric) {
        if (ric) {
            if (judge) {
                router.push({
                    name: "Warrant Terms",
                    query: { dsplay_nmll: ric }
                });
            } else {
                router.push({
                    name: "Technical Chart",
                    params: { ric: ric }
                });
            }
        }
    },
    // 导航栏动画效果
    navbarAnimation: function () {
        setTimeout(function () {
            $("#header")
                .find(".navbar-nav")
                .addClass("navbar-nav_animation");
        }, 10);
    },
    // Disclaimer 出现
    DisclaimerModalShow: function () {
        $("#DisclaimerModal").modal("show");
    },
    // Disclaimer Close
    DisclaimerModalClose: function () {
        var today = new Date().getTime();
        localStorage.setItem("disclaimerchoice", today);
    },
    // 拿到列表的宽度
    tableWidth: function (div1, div2, vif) {
        setTimeout(function () {
            div1.width(div2.width());
            div1.parent().scroll(function () {
                div1.width(div2.width());
                div2.parent().scrollLeft(div1.parent().scrollLeft());
                if (vif) {
                    $(".headTableBox").scrollLeft(div1.parent().scrollLeft());
                }
            });
            div2.parent().scroll(function () {
                div1.parent().scrollLeft(div2.parent().scrollLeft());
                if (vif) {
                    $(".headTableBox").scrollLeft(div2.parent().scrollLeft());
                }
            });
        }, 10);
    },
    // 相应数据提示
    theCorrespondingDataHint: function (judge, name, e, top, left, name2) {
        switch (name) {
            case "Delta":
                if (judge) {
                    $(".DeltaHint").css({
                        top: e.srcElement.getBoundingClientRect().y - top,
                        left: e.srcElement.getBoundingClientRect().x - left
                    });
                    $(".DeltaHint").show();
                } else {
                    $(".DeltaHint").hide();
                }
                break;
            case "Implied":
                if (judge) {
                    $(".ImpliedVolatilityHint").css({
                        top: e.srcElement.getBoundingClientRect().y - top,
                        left: e.srcElement.getBoundingClientRect().x - left
                    });
                    $(".ImpliedVolatilityHint").show();
                } else {
                    $(".ImpliedVolatilityHint").hide();
                }
                break;
            case "Premium":
                if (judge) {
                    $(".PremiumHint").css({
                        top: e.srcElement.getBoundingClientRect().y - top,
                        left: e.srcElement.getBoundingClientRect().x - left
                    });
                    $(".PremiumHint").show();
                } else {
                    $(".PremiumHint").hide();
                }
                break;
            case "Sensitivity":
                if (judge) {
                    $(".SensitivityHint").css({
                        top: e.srcElement.getBoundingClientRect().y - top,
                        left: e.srcElement.getBoundingClientRect().x - left
                    });
                    $(".SensitivityHint").show();
                } else {
                    $(".SensitivityHint").hide();
                }
                break;
            case "Effective":
                if (judge) {
                    $(".EffectiveGearingHint").css({
                        top: e.srcElement.getBoundingClientRect().y - top,
                        left: e.srcElement.getBoundingClientRect().x - left
                    });
                    $(".EffectiveGearingHint").show();
                } else {
                    $(".EffectiveGearingHint").hide();
                }
                break;
            case "Theta":
                if (name2 == 'Theta (THB/days)') {
                    if (judge) {
                        $(".ThetaHint1").css({
                            top: e.srcElement.getBoundingClientRect().y - top,
                            left: e.srcElement.getBoundingClientRect().x - left
                        });
                        $(".ThetaHint1").show();
                    } else {
                        $(".ThetaHint1").hide();
                    }
                } else if (name2 == 'Theta (%/days)') {
                    if (judge) {
                        $(".ThetaHint2").css({
                            top: e.srcElement.getBoundingClientRect().y - top,
                            left: e.srcElement.getBoundingClientRect().x - left
                        });
                        $(".ThetaHint2").show();
                    } else {
                        $(".ThetaHint2").hide();
                    }
                } else if (name2 == 'Theta (days)') {
                    if (judge) {
                        $(".ThetaHint3").css({
                            top: e.srcElement.getBoundingClientRect().y - top,
                            left: e.srcElement.getBoundingClientRect().x - left
                        });
                        $(".ThetaHint3").show();
                    } else {
                        $(".ThetaHint3").hide();
                    }
                }

                break;
        }
    },
    // 相应数据提示
    decodeHTML: function (value) {
        if (value == undefined || value == null) {
            return "";
        } else {
            return $("<div />")
                .html(value)
                .text();
        }
    },
    // 计算
    blackScholes: blackScholes,
    // 判断是否是手机
    _isMobile() {
        let flag = /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent)
        return flag;
    }
};
