import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
export default {
    name: "App",
    created () {
        this.$nextTick(function () {
            // localStorage.clear()
            if (this.$route.path == "/mst/" || this.$route.path == "/mst") {
                this.$router.push("/mst/Home");
            }
            this.DisclaimerLink()
        });
    },
    mounted () {
        this.$nextTick(function () {
            // 监听屏幕
            if ($(window).width() <= 992) {
                // Header导航栏添加点击事件
                $("#header")
                    .find(".navbar-nav")
                    .addClass("mobile");
                //  Footer导航栏添加点击事件
                $("#Footer")
                    .find(".nav_box")
                    .addClass("mobile");
            } else {
                // Header导航栏去除点击事件
                $("#header")
                    .find(".navbar-nav")
                    .removeClass("mobile");
                // Header导航栏移除内联样式
                $("#header")
                    .find(".navbar-nav")
                    .find(".dropdown-menu")
                    .removeAttr("style");
                //  Footer导航栏去除点击事件
                $("#Footer")
                    .find(".nav_box")
                    .removeClass("mobile");
                // Footer导航栏移除内联样式
                $("#Footer")
                    .find(".nav_box")
                    .find(".dropdown > ul")
                    .removeAttr("style");
            }
            $(window).resize(function () {
                if ($(window).width() <= 992) {
                    // Header导航栏添加点击事件
                    $("#header")
                        .find(".navbar-nav")
                        .addClass("mobile");
                    //  Footer导航栏添加点击事件
                    $("#Footer")
                        .find(".nav_box")
                        .addClass("mobile");
                } else {
                    // Header导航栏去除点击事件
                    $("#header")
                        .find(".navbar-nav")
                        .removeClass("mobile");
                    // Header导航栏移除内联样式
                    $("#header")
                        .find(".navbar-nav")
                        .find(".dropdown-menu")
                        .removeAttr("style");
                    //  Footer导航栏去除点击事件
                    $("#Footer")
                        .find(".nav_box")
                        .removeClass("mobile");
                    // Footer导航栏移除内联样式
                    $("#Footer")
                        .find(".nav_box")
                        .find(".dropdown > ul")
                        .removeAttr("style");
                }
            });
        });
    },
    provide () {
        //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
        return {
            relode: this.relode
        };
    },
    data () {
        return {
            headerLogoBlack: require("../../public/image/header_logo_black.png"), // Header导航栏logo 动画之后
            headerLogoWhite: require("../../public/image/header_logo_white.png"), // Header导航栏logo 动画之前
            thisPageSite: null, // 当前页面的url地址
            routerF: true
        };
    },
    watch: {
        $route: {
            handler: "routeCue",
            // 深度观察监听
            deep: true
        }
    },
    methods: {
        SomehowThePromptModalClose: function (name) {
            localStorage.setItem(name, true);
        },
        relode: function () {
            this.routerF = false;
            this.$nextTick(() => {
                this.routerF = true;
            });
        },
        routeCue: function (val, oldVal) {
            if (val.meta.title) {
                document.title = val.meta.title;
            }
            if (val.meta.content) {
                let head = document.getElementsByTagName("head");
                let meta = document.createElement("meta");
                document
                    .querySelector('meta[name="keywords"]')
                    .setAttribute("content", val.meta.content.keywords);
                document
                    .querySelector('meta[name="description"]')
                    .setAttribute("content", val.meta.content.description);
                meta.content = val.meta.content;
                head[0].appendChild(meta);
            }
            let _this = this;
            // 接受当前页面的url地址
            if (val.name) {
                this.thisPageSite = this.$t(val.meta.name).toUpperCase();
            }

            // 不是首页的导航栏的css
            if (this.thisPageSite == "HOME") {
                $("#header")
                    .find(".navbar-nav")
                    .css({
                        left: "100%"
                    });
                if ($(document).scrollTop() == 0) {
                    $("#header").css({
                        position: "relative"
                    });
                } else {
                    $("#header").css({
                        position: "fixed"
                    });
                }
                // 监听滚动条
                $(window).scroll(function () {
                    if ($(document).scrollTop() == 0) {
                        $("#header").css({
                            position: "relative"
                        });
                    } else {
                        $("#header").css({
                            position: "fixed"
                        });
                    }
                });
            } else {
                $("#header").css({
                    position: "relative",
                    left: "0"
                });
                $(window).unbind("scroll");
            }
            $("#mobile_navbar")
                .find(".mobile_navbar-nav")
                .animate(
                    {
                        width: "0"
                    },
                    500
                );
            setTimeout(function () {
                $("#mobile_navbar").hide();
            }, 510);
            $("#mobile_navbar")
                .find(".dropdown-toggle")
                .parent()
                .nextAll()
                .slideUp();
            // $('#header').find(".navbar-nav").removeClass('navbar-nav_animation')

            if (
                this.thisPageSite == this.$t("nav.WS").toUpperCase() ||
                this.thisPageSite == this.$t("nav.WC").toUpperCase() ||
                this.thisPageSite == this.$t("nav.WTe").toUpperCase() ||
                this.thisPageSite == this.$t("nav.TC").toUpperCase()
            ) {
                window.addEventListener(
                    "orientationchange",
                    function () {
                        if (Math.abs(window.orientation) !== 90) {
                            if (
                                localStorage.getItem(this.$t("nav.WS").toUpperCase()) == null &&
                                _this.thisPageSite == this.$t("nav.WS").toUpperCase()
                            ) {
                                $("#SomehowThePromptModal").modal("show");
                            }
                            if (
                                localStorage.getItem(this.$t("nav.WC").toUpperCase()) == null &&
                                _this.thisPageSite == this.$t("nav.WC").toUpperCase()
                            ) {
                                $("#SomehowThePromptModal").modal("show");
                            }
                            if (
                                localStorage.getItem(this.$t("nav.WTe").toUpperCase()) ==
                                null &&
                                _this.thisPageSite == this.$t("nav.WTe").toUpperCase()
                            ) {
                                $("#SomehowThePromptModal").modal("show");
                            }
                            if (
                                localStorage.getItem(this.$t("nav.TC").toUpperCase()) == null &&
                                _this.thisPageSite == this.$t("nav.TC").toUpperCase()
                            ) {
                                $("#SomehowThePromptModal").modal("show");
                            }
                        }
                    },
                    false
                );
            }
            this.relode();
        },
        DisclaimerLink: function () {
            let _this = this
            $('#DisclaimerModal').find('.DisclaimerModalH2').find('a').click(function () {
                if ($(this).index() == 0) {
                    if (localStorage.getItem("langIH") == 1) {
                        $(this).attr({ "href": "https://www.maybank-ke.co.th/media/407225/disclaimereng.pdf", "target": "_blank" })
                    } else {
                        $(this).attr({ "href": "https://www.maybank-ke.co.th/media/407226/disclaimerth.pdf", "target": "_blank" })
                    }
                   
                } else if ($(this).index() == 1) {
                    let tempwindow = window.open('_blank');
                    tempwindow.location = _this.$router.resolve({ name: "Disclaimer" }).href;
                } else if ($(this).index() == 2) {
                    if (localStorage.getItem("langIH") == 1) {
                        $(this).attr({ "href": "https://www.maybank-ke.co.th/media/407434/msthprivacypolicynotice-webeng_9feb2022.pdf", "target": "_blank" })
                    } else {
                        $(this).attr({ "href": "https://www.maybank-ke.co.th/media/407435/msthprivacypolicynotice-webthai_9feb2022.pdf", "target": "_blank" })
                    }
                   
                }
            })
        }
    },
    components: {
        Header,
        Footer
    }
};
