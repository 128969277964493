export default {
  name: "Header",
  created() {
    this.$nextTick(function() {
        // localStorage.clear();
      this.navbarReverse();
      if (localStorage.getItem("langIH") !== null) {
        this.langAi = localStorage.getItem("langIH");
      } else {
        this.langAi = 2;
        localStorage.setItem("langIH", 2);
      }
    });
  },
  mounted() {},
  inject: ["relode"],
  data() {
    return {
      navigation: [], // 导航栏数组
      headerLogoBlack: require("../../../public/image/header_logo_black.png"), // 导航栏logo 动画之后
      headerLogoWhite: require("../../../public/image/header_logo_white.png"), // 导航栏logo 动画之前
      isFirst: true, // 让函数方法执行一次的开关
      langAi: 1
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 接受导航栏内容去开头两个
    navbarReverse: function() {
      let _this = this;
      $.each(this.$router.options.routes, function(index, routes) {
        if (index > 1) {
          _this.navigation.push(routes);
        }
      });
    },
    // 移动端导航的出现或消失
    mobileNavbarSwitch: function(ifv) {
      if (ifv) {
        $("#mobile_navbar").show();
        $("#mobile_navbar")
          .find(".mobile_navbar-nav")
          .animate(
            {
              width: "300px"
            },
            500
          );
      } else {
        $("#mobile_navbar")
          .find(".mobile_navbar-nav")
          .animate(
            {
              width: "0"
            },
            500
          );
        setTimeout(function() {
          $("#mobile_navbar").hide();
        }, 510);
      }
    },
    // 移动端导航栏点击出二级导航
    outMoversNavbar: function(index) {
      $("#mobile_navbar")
        .find(".dropdown-toggle")
        .eq(index)
        .parent()
        .nextAll()
        .slideToggle();
    },
    // 移动端二级导航栏点击出三级导航
    outThreeNavbar: function(index) {
      $("#mobile_navbar")
        .find(".dropdown-submenu")
        .eq(index)
        .find("ul")
        .slideToggle();
    },

    changeLang(language, lang) {
      this.$i18n.locale = language;
      localStorage.setItem("languageIH", language);
      this.langAi = lang;
      localStorage.setItem("langIH", lang);
      this.relode();
      this.$router.go(0);
    }
  }
};
